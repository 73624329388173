import { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useLocation, BrowserRouter as Router } from 'react-router-dom';

import api from './services/api';

import GlobalStyle from './styles/global';
import { Routes } from './routes/index';
import squadra from './styles/themes/squadra';

function App(): JSX.Element {
  const [theme, setTheme] = useState(squadra);
  const { pathname } = useLocation();

  const organizationId = pathname.replaceAll('/', '');

  useEffect(() => {
    if (!organizationId || organizationId === 'health') return;

    try {
      api
        .get(`assets/${organizationId}/theme-home`)
        .then(response => setTheme(response.data));
    } catch (error) {
      setTheme(squadra);
    }
  }, [organizationId]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes />
      </Router>

      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
